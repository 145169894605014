/** Ends and Odds: Richard Hats **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data;

class EndsAndOddsPage4 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[4];

    //refs
    this.bgVideoRef = null;
  }

  state = {
    textVisible: false,
  }

  
  

  componentDidMount = () => {
    
    setTimeout(()=>{
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);
    
    //show text
    gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
    
    //If coming to this chapter by clicking prev
    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);  //basetrack
      globalState.baseAudioRef.updateTrack(2, false);  //remove prev chapter basetrack
    }
    

    //Preload assets from next page
    preloadImages([globalState.cdnUrl + data.nextTitle], this.preloadCallback);

  }

  preloadCallback = () =>{
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  

  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    
  }

  videoEnded = () => {
    console.log("Bg Video ended");
    //this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({textVisible: !this.state.textVisible});

    //reset the animation delay
    resetAnimationDelay();

    if(this.state.textVisible){
      //show text
      showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);
      

    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () =>{
    this.bgVideoRef.pauseVideo();
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/cosmos-954" />
        
        <PrevNext globalState={globalState} ref="prevNextRef" 
        nextLocation="/cosmos-954" prevLocation="/ends-and-odds/3"
        isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Ends and Odds" />

          <div className="text-wrapper">
            <div className="text text--right-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "40px", marginTop: "20px" }} onClick={this.toggleTextDisplay} alt="Richard doesn’t have a lot of keepsakes, but he does have a dozen hats from Pine Point in his bottom right-hand dresser drawer, hats that a younger version of himself once wore. He took the time to make sure each one looked good before we started filming." />
            </div>
          </div>

          {data.bgVideo ? 
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo} 
            poster={globalState.cdnUrl + data.bgVideoPoster} muted={false} autoPlay={false} loop={false} ended={this.videoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }
          
        </div>
        )}
      </>
    );
  }


};

export default EndsAndOddsPage4
